// import Navbar from './navbar/Navbar';
import Navabar from './navbar/Navabr';
import { BrowserRouter, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import LandingPage from './landing-page/LandingPage';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Aos from 'aos';
import ThankYouPage from './ThankYouPage';
import ReactGA from 'react-ga4';

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000, // Duration of the animations in milliseconds
      easing: 'ease-in-out', // Animation easing
      offset: 200, // Offset (in pixels) from the original trigger point
      // Whether animation should happen only once - while scrolling down
    });


    ReactGA.initialize('GT-TNGXLK2G'); // Replace with your GA4 Measurement ID

  }, []);

  return (
    <>
      <HashRouter>
        <Navabar />
        <Routes>
          <Route path='/' element={<Navigate to="/pankhdevelopers/4bhk-luxury-faridabad" />}  />
          <Route path='/pankhdevelopers/4bhk-luxury-faridabad' element={<LandingPage />} />
          <Route path='/pankhdevelopers/4bhk-luxury-faridabad/thankyou' element={<ThankYouPage />} />
          <Route path='*' element={<Navigate to="/pankhdevelopers/4bhk-luxury-faridabad" />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
