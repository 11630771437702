import React, { useState } from 'react'
import brandlogo from '../../asests/images/logo2-removebg-preview.png'
function FooterSection() {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const disclaimertext = {
        // text1:"The content provided on this website is for information purposes only and does not constitute an offer to avail any service. The prices mentioned are subject to change without prior notice, and the availability of properties mentioned is not guaranteed.",
        text2:"The content provided on this website is for information purposes only and does not constitute an offer to avail any service. The prices mentioned are subject to change without prior notice, and the availability of properties mentioned is not guaranteed. The images displayed on the website are for representation purposes only and may not reflect the actual properties accurately. Please note that this is the official website of an authorized marketing partner. We may share data with Real Estate Regulatory Authority (RERA) registered brokers/companies for further processing as required. We may also send updates and information to the mobile number or email ID registered with us. All rights reserved. The content, design, and information on this website are protected by copyright and other intellectual property rights. Any unauthorized use or reproduction of the content may violate applicable laws. For accurate and up-to-date information regarding services, pricing, availability, and any other details, it is advisable to contact us directly through the provided contact information on this website. Thank you for visiting our website"
    }
  return (
    <div className='container-fluid pading-yline footerbg'>
       
          <div className="container border-top border-bottom py-3">
            <div className="row">
            <div className="col-12 col-md-12 px-md-2 text-center text-md-start">
                 <div className='footer-brand-img mt-1'>
                     <img src={brandlogo} style={{width:'200px', height:'100px'}} alt="" />
                 </div>   
            </div>
            <div className="col-12 col-md-6">
            <div>
                 {/* <p className='mb-0' style={{fontWeight:'bold', color:'white'}}>Pankh Developers :</p> */}
                  <p style={{textAlign:'justify', lineHeight:'normal'}}>Renowned for crafting quality homes with a blend of trust and innovation, Pankh Developers is dedicated to delivering exceptional living experiences through meticulously designed and sustainable projects</p> 
                 </div>
            </div>

            <div className="col-12 col-md-6 border-footer ps-md-3" >
            <div className='d-flex align-items-start flex-wrap'>
                    <p className='mb-0' style={{fontWeight:'bold', color:'white'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt mb-1" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>{" "} &nbsp;</p> 
                    <p>
                    
                        Shop no 53 sector 46 market Faridabad</p>
                 </div>
                 <div className='d-flex align-items-center flex-wrap'>
                    <p style={{fontWeight:'bold', color:'white'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone mx-1" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>  &nbsp;</p>
                    <p>
                    
                        +91 97113 56356</p>
                 </div>
            </div>


                <div className="col-12 col-md-12 ">
                    {/* <p style={{fontWeight:'bold', color:'white'}} className='mb-0'>Disclaimer :</p> */}
                    {/* <p className='mb-0' style={{fontSize:'12px', lineHeight:'normal'}}>{disclaimertext.text1}</p> */}
                    <p className='mt-0' style={{fontSize:'12px', textAlign:'justify', lineHeight:'normal'}}>{isExpanded ? disclaimertext.text2 : `${disclaimertext.text2.slice(0, 300)}...`}
                    <a className=' rounded' onClick={handleToggleReadMore} style={{marginLeft: '10px', border:'none',  background: 'none', color: 'gray', cursor: 'pointer'}}>
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </a>
                    </p>
                </div>
                
                {/* <div className="col-12 col-md-6">
                    <h6>RERA Number of the Property.</h6>
                    <p>P52100012345</p>
                </div>
                <div className="col-12 col-md-6">
                    <h6>RERA Number of the Dealer.</h6>
                    <p>P52100012345</p>
                </div> */}
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <div>
                        <a style={{textDecoration:'none', fontSize:'12px'}} href="/">Privacy Policy</a>
                    </div>
                    <div>
                        <a style={{textDecoration:'none', fontSize:'12px'}} href="/">Term And Condition</a>
                    </div>
                </div>
            </div>
          </div>
    </div>
  )
}

export default FooterSection