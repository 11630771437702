import React, { useState } from 'react'

function LocationMap() {
    const [items] = useState([
        'Spacious Layout: 500 sq. yds. of luxurious living space.',
        'Prime Connectivity: Just 2 km from Manav Rachna-Badkal Mor Metro.',
        'Premium Interiors: Luxurious Italian marble flooring throughout the flat.',
        'Sunlit Rooms: Large windows in every room for abundant natural light',
        'High-End Modular Kitchen: Equipped with modern appliances and sleek fixtures.',
        'Spa-Inspired Bathrooms: Featuring high-end fixtures and serene ambiance.',
        'Home – Automation – Wired home automation with both app-based & voice commands'

    ]);
    return (
        <div id='section6' className="container-fluid position-relative py-4 py-md-5">
            {/* <div className="section6-bg-dots"></div> */}
            <div className="container">
                <div className="row">
                    <div className="col-12 order-1 h3-pb">
                        <h2 className='text-center'>Property Location</h2>
                    </div>
                    <div className="col-12 order-3 d-flex align-items-center col-md-6">
                        <div>
                            <h5 className='text-start  ' >Key Features</h5>
                            <div>
                                {items.map((item, idx) => (
                                    <div key={idx + 1} className='d-flex justify-content-start mb-2 p-0 align-items-start'>
                                        <div className='checked-map-container'>
                                            <span class="material-symbols-outlined checked-icon-map" style={{ overflow: 'hidden', fontSize: '20px', color: '#cee002' }}>
                                                check_box
                                            </span>
                                        </div>

                                        {/* <svg xmlns="http://www.w3.org/2000/svg" style={{color:'#cee002'}} width="14" height="14" fill="currentColor" className="bi bi-check-square checked-icon-custom me-3" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg> */}
                                        {/* <input class="form-check-input custom-checkbox me-3 text-dark" type="checkbox" value="" id="flexCheckChecked"  checked /> */}

                                        <p className='p-0 m-0' >{item}</p>

                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* <ul className='mt-3 key-feature' style={{listStyleType:'none'}}>
                {items.map((item, index) => (
                    <li key={index} className='d-flex align-items-start mb-2 '>
                        <input class="form-check-input custom-checkbox me-1 text-dark" type="checkbox" value="" id="flexCheckChecked"  checked />
                        {item}
                    </li>
                ))}
            </ul> */}
                        {/* <div className="row">
                        <div className="col-12">
                            <h4>Park Hospital</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam nobis aspernatur sapiente rem ducimus reiciendis maiores ut praesentium eligendi mollitia?</p>
                        </div>
                        <div className="col-12">
                            <h4>Garhi Harsaru Junction</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam nobis aspernatur sapiente rem ducimus reiciendis maiores ut praesentium eligendi mollitia?</p>
                        </div>
                        <div className="col-12">
                            <h4>Gurgaon World School</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam nobis aspernatur sapiente rem ducimus reiciendis maiores ut praesentium eligendi mollitia?</p>
                        </div>
                        <div className="col-12">
                            <h4>Elan Miracle</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam nobis aspernatur sapiente rem ducimus reiciendis maiores ut praesentium eligendi mollitia?</p>
                        </div>

                      </div> */}
                    </div>
                    <div className="col-12 col-md-6 order-2 ">
                        <div className='rounded map-container me-2 ' style={{ overflow: 'hidden' }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14034.529202147285!2d77.28531280233803!3d28.430349912408335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cddef4e145f65%3A0xf7977c26d3a257b2!2sSector%2021C%2C%20Faridabad%2C%20Haryana%20121001!5e0!3m2!1sen!2sin!4v1716807258415!5m2!1sen!2sin" width="100%" height="100%" style={{ border: '0', marginBottom: '' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d112238.85675359101!2d77.0146304!3d28.465561599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1715853439133!5m2!1sen!2sin" width="100%" height="350" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationMap