import React from 'react'
import logo from '../asests/images/logo2.jpg'
function Navabar() {


  // const handleScroll = (id) => {
  //   document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  // };


  



  return (
    
    <nav className="navbar p-0 shadow-sm"  style={{backgroundColor:'rgba(255, 255, 255, 0.8)'}}>
    <div className="container-fluid align-items-center ">
      <div className="navbar-brand navbar-brand-logo p-0" style={{color:'black'}}  href="/">
        <img src={logo} alt="Logo"  style={{objectFit:'contain'}} className="d-inline-block me-2" />
      {/* <span className='brand-text-nav' style={{color:'black'}}>Punkh Developers</span> */}
      </div>
      {/* <ul className='d-none  p-0 m-0 d-md-flex jstify-content-around align-items-center gap-col-2' style={{listStyleType:'none', width:'60%'}} >
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section1')}>Home</li>
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section2')}>Property Description</li>
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section3')}>Lead Form</li>
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section4')}>Amenities</li>
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section5')}>Size & Price</li>
        <li style={{cursor:'pointer'}} onClick={() => handleScroll('section6')}>Location</li>
      </ul> */}
    </div>
  </nav>
  )
}

export default Navabar