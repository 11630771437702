import React, { useEffect, useRef, useState } from 'react'
import PropertyDescription from './components/PropertyDescription'
import LeadFormSection from './components/LeadFormSection'
import SizeAndPriceSection from './components/SizeAndPriceSection'
import LocationMap from './components/LocationMap'
import FooterSection from './components/FooterSection'
//import AmenitiesSection2 from './components/AmenitiesSection2'
import HeroAndOverview from './components/HeroAndOverview'
import './components/components.css'
import "yet-another-react-lightbox/styles.css";
import { Helmet } from 'react-helmet'
import LeadFormWithCaptcha from './components/LeadFormWithCaptch'

function LandingPage() {
  const leadFormSectionRef = useRef(null);
  const [isFloatingButtonVisible, setFloatingButtonVisible] = useState(true);

  const scrollToLeadFormSection = () => {
    leadFormSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setFloatingButtonVisible(!entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0.1, // Trigger when 10% of the target is visible
      }
    );

    if (leadFormSectionRef.current) {
      observer.observe(leadFormSectionRef.current);
    }

    return () => {
      if (leadFormSectionRef.current) {
        observer.unobserve(leadFormSectionRef.current);
      }
    };
  }, []);

  return (
    <>
    


    <div style={{background:'#272727'}}>
    <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16594647001"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16594647001');
          `}
        </script>
        <script>
          {`
            gtag('event', 'conversion', {
              'send_to': 'AW-16594647001/cUAQCJDV37YZENnv-Og9',
              'value': 1.0,
              'currency': 'INR'
            });
          `}
        </script>
      </Helmet>

    <HeroAndOverview onClick={scrollToLeadFormSection}/>
    {/* <LeadFormSection refs={leadFormSectionRef}/> */}
    <LeadFormWithCaptcha refs={leadFormSectionRef} />
    <PropertyDescription/>
    <SizeAndPriceSection/>
    <LocationMap/>
    <FooterSection/>
    </div>
   
     
    
    </>
  )
}

export default LandingPage