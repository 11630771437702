import React, { useState } from 'react';
import contactimg from '../../asests/images/IMG-20240310-WA0098.jpg';
import { Box, TextField, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useSnackbar } from '../../SnackBarProvider';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'; // Importing reCAPTCHA component

function LeadFormWithCaptcha({ refs }) {
  const [apiError, setApiError] = useState(null);
  const [numberTen, setNumberTen] = useState('');
  const [enableButton, setEnableButton] = useState(false);
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const [captcha, setCaptch] = useState(false)
  const [captchError, setCaptchError] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      number: Yup.string()
        .matches(/^\d{10}$/, 'Enter a valid 10-digit number')
        .required('Number is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      onSubmitForm(values, resetForm);
    },
  });

  const onSubmitForm = async (values, resetForm) => {
       if(!captcha){
        
        setCaptchError(true)
         return

       }
       setCaptchError(false)
    setEnableButton(true);
    const body = {
      user: 'pankh developers',
      name: values.name,
      mobile: values.number,
    };
    
    try {
      // Make sure to replace with your reCAPTCHA site key
      // const token = await recaptchaRef?.current?.executeAsync();
      const res = await axios.post('https://demo.leapxads.com/api/landing-page-lead', {
        ...body,

      });

      if (res?.status === 200 && res.data?.valid) {
        showSnackbar(
          `Success: Your details have been submitted successfully! We will get back to you soon.`,
          'success'
        );
        resetForm();
        navigate('/pankhdevelopers/4bhk-luxury-faridabad/thankyou', { state: { formSubmitted: true } });
       // navigate('/pankhdevelopers/4bhk-luxury-faridabad/thankyou');
      } else {
        showSnackbar(`Error: Server Error, Please Try Again`, 'error');
      }
    } catch (error) {
      // showSnackbar(`Error: Server Error`, 'error');
      console.log(error?.message);
    } finally {
      setEnableButton(false);
    }
  };

  const recaptchaRef = React.createRef(); // Create a ref for the reCAPTCHA component

  return (
    <div className="container-fluid py-4 py-md-5 position-relative" id="section3" ref={refs}>
      <div className="section3-dots"></div>
      <div className="container section3-middle">
        <div className="row">
          <div className="col-12">
            <div className="h3-pb">
              <h2 className="text-center">Enquire Now</h2>
              <p className="text-center">Please Share Your Details</p>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 mb-md-0 me-0">
            <div className="position-relative rounded" style={{ zIndex: '99' }}>
              <img src={contactimg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center ms-0">
            <div className="enquirynow-container shadow">
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': {
                    m: 1,
                    width: '90%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'gray',
                      },
                      '&:hover fieldset': {
                        borderColor: '#cee002',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#cee002',
                      },
                      '& input': {
                        color: 'white',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#cee002',
                    },
                  },
                }}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <div className="mb-2 mb-md-3">
                  <div className="ps-2">
                    <b>Name</b>
                  </div>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    size={isSmallScreen ? 'small' : 'medium'}
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="mb-2 mb-md-3 position-relative">
                  <div className="ps-2">
                    <b>Mobile Number</b>
                  </div>
                  <TextField
                    fullWidth
                    id="number"
                    name="number"
                    sx={{ color: 'white' }}
                   
                    placeholder="Mobile Number"
                    size={isSmallScreen ? 'small' : 'medium'}
                    value={formik.values.number}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setNumberTen(e.target.value);
                      setApiError(null);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.number && Boolean(formik.errors.number)}
                    helperText={formik.touched.number && formik.errors.number}
                    InputProps={{
                      sx: {
                        color: numberTen.length === 10 ? 'white' : 'black',
                        '& .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                          color: 'white',
                        },
                      },
                    }}
                  />
                  {formik.touched.phone ? (
                    <p
                      className="text-end text-danger position-absolute"
                      style={{ top: '48px', right: '0px', fontSize: '0.73rem', textTransform: 'initial' }}
                    >
                      {numberTen.length === 10 && apiError ? apiError : null}
                    </p>
                  ) : null}
                </div>

                {/* Google reCAPTCHA */}
                <div className="mb-2 mb-md-3 position-relative captcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6LcsW_kpAAAAAMKFPwX3Hmk1uoOUEpncoqb9dZJp" // Replace with your reCAPTCHA site key
                    onChange={() => {
                        setCaptch(true); setCaptchError(false)
                    }}
                    onErrored={() => {
                      // Handle reCAPTCHA error if needed
                    }}
                    onExpired={() => {
                      // Handle reCAPTCHA expiration if needed
                    }}
                    theme="dark"
                    className="custom-recaptcha ps-2"
                    
                  />
                  {captchError && <p style={{fontSize:'12px'}} className='text-start text-danger'>Please check the captcha.</p>}
                </div>

                <div className="mt-4 mt-md-5">
                  <button type="submit" disabled={enableButton} className="leadform-btn shadow-sm">
                    <span>Submit</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: '#333' }}
                      width="26"
                      height="26"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill ms-4"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadFormWithCaptcha;
