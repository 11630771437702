import React from 'react'
// import propertyimg1 from '../../asests/property1.jpg'
// import propertyimg2 from '../../asests/property2.jpg'
// import propertyimg3 from '../../asests/property3.jpg'
import image2 from '../../asests/images/IMG-20240310-WA0098.jpg'
import image3 from '../../asests/images/IMG-20240310-WA0086.jpg'
import image1 from '../../asests/images/IMG-20240310-WA0082.jpg'
function PropertyDescription() {


const propertyimgtext = [
  {img:image1, heading:'Fusion of Classic and Contemporary Interior Design', text:'The interior design of this luxurious flat is a unique blend of classic elegance and contemporary style, offering a sophisticated and timeless aesthetic. This fusion creates a harmonious living environment, setting a new standard in Faridabad for upscale, modern living.'},
  {img:image2, heading:'Superior Construction and Luxurious Interiors', text:'Built with high-quality materials and superior craftsmanship, this flat boasts a robust and elegant structure. Enjoy luxurious Italian flooring throughout, spa-inspired bathrooms with high-end fixtures, and well-designed bedrooms with contemporary decor and spacious layouts, ensuring a premium living experience.'},
  {img:image3, heading:'High-End Modular Kitchen', text:'The state-of-the-art modular kitchen is equipped with sleek cabinets, premium countertops, and modern appliances, perfect for culinary enthusiasts. Designed for both functionality and style, it offers an ideal space for cooking and dining.'},
]


  return (
      <div className="container-fluid py-4 py-md-5" id='section2'>
        <div className="container">
             <div className="row">
              <div className="col-12">
                <h2 className='text-center h3-pb'>Property Key Highlights</h2>
              </div>
             </div>
            {propertyimgtext.map((val, index)=>(
              <>
               <div key={index+1}  className={index%2 !==0?'row flex-row-reverse py-md-3':'row  py-md-3 '} >
                <div className="col-12 col-md-6 p-2 d-flex flex-column flex-md-row justify-content-start align-items-start align-items-md-center">
                          <div className=''>
                          <h5 className='mb-3 mb-md-0 ' style={{textTransform:'capitalize', fontWeight:'500', opacity:'0.8', color:'white'}}>{val.heading}</h5>
                       <p className='d-none mt-3 d-md-block' style={{textAlign:'justify'}}>{val.text}</p>
                          </div>
                       
                       {/* mobile */}
                       <div className=' d-block d-md-none' style={{width:'100%', height:'200px',}}>
                    <img src={val.img} loading='lazy'  alt="" style={{width:'100%', height:'100%',  objectFit:'cover'}} />
                  </div>

                </div>
                <div className="col-12 col-md-6 p-2">
                  <div className='px-3 d-none d-md-block' style={{width:'100%'}}>
                    <img src={val.img} loading='lazy'  alt="" style={{width:'100%', height:'300px',  objectFit:'cover'}} />
                  </div>
                  {/* mobile */}
                  <p className='d-md-none d-block'>{val.text}</p>
                </div>
            </div>
              </>
            ))}
           
           
        </div>
      </div>
      
  )
}

export default PropertyDescription