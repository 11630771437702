import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import heroimg from '../../asests/images/IMG-20240310-WA0075.jpg'
import heroimg6 from '../../asests/images/IMG-20240310-WA0098.jpg'
import heroimg5 from '../../asests/images/IMG-20240310-WA0078.jpg'
import Lightbox from "yet-another-react-lightbox";
import heroimg2 from '../../asests/images/IMG-20240310-WA0086.jpg'

import heroimg3 from '../../asests/images/IMG-20240310-WA0077.jpg'

// import heroimg4 from '../../asests/images/IMG-20240310-WA0075.jpg'

import price from '../../asests/Price.png'

const arrayimg2 = [
  { id: 1, src: heroimg, texth: "First slide label" },
  { id: 2, src: heroimg2, texth: "First slide label"},
  { id: 3, src: heroimg3, texth: "First slide label" },
  // { id: 4, src: heroimg4, texth: "First slide label" },
  { id: 5, src: heroimg5, texth: "First slide label" },
  { id: 6, src: heroimg6, texth: "First slide label"}

]



function HeroAndOverview({onClick}) {
  const [index, setIndex] = useState(0);
  
  const [basicExampleOpen, setBasicExampleOpen] = React.useState(false);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };


  const arrayimg = [
    { id: 1, img: heroimg, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." },
    { id: 2, img: heroimg2, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." },
    { id: 3, img: heroimg3, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." },
    // { id: 3, img: heroimg4, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." },
    { id: 4, img: heroimg5, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." },
    { id: 5, img: heroimg6, texth: "First slide label", textp: "Nulla vitae elit libero, a pharetra augue mollis interdum." }

  ]

  return (
    <>

   {/* mobile */}

   <Lightbox
        open={basicExampleOpen}
        close={() => setBasicExampleOpen(false)}
        slides={arrayimg2}
      />

      {/* <button onClick={() => setBasicExampleOpen(true)} >open</button> */}




      <div className="d-md-none container-fluid  p-0 m-0">
        <div className="row">
            <div className="col-12 py-2 px-1">
            <h4 style={{color:'white', textTransform:'uppercase'}} className='top-hero-text text-center'>Exquisite 4BHK Flat by Pankh Developers</h4>
            </div>
          <div className="col-12 col-md-6 position-relative hero-main-height">
            <Carousel controls={false} indicators={true} activeIndex={index} onSelect={handleSelect} style={{ width: '100%' }}>
              {arrayimg?.map((val) => (
                <Carousel.Item key={val.id} className='position-relativ'>
                  <img src={val.img} className='hero-carousel-photos' alt="" onClick={() => setBasicExampleOpen(true)} />
                </Carousel.Item>
              ))}
            </Carousel>
            

            <div className="top-box-location d-flex justify-content-center align-items-start">
              <div className='location-hero-container text-center position-relative'>
                <div className='d-flex justify-content-center'>
                <h5 className='bhk-text1-mobile text-center mt-0 mt-2' style={{color:'', maxWidth:'94%'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt mb-1" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>{" "}
                
Sector 21C, Faridabad</h5>
                </div>
             
                 
              </div>
            </div>

            <div className='hero-box-bottom d-flex justify-content-center align-items-center'>
                 <div className="hero-box-bottom-child py-3 px-1 me-4">
                  {/* <h3 className='text-center'>M3M MANSION</h3> */}
                  
                  <h5 className=' p-1 pb-2 mx-1 price-hero-text  text-center mb-0' style={{color:'white'}}><img src={price} width={30} height={30}  style={{color:'white'}}  alt="" /> {""}Price :
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-currency-rupee mb-1" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                </svg>
                   3.5 Cr* Onwards</h5>

                   <h5 className='size-text text-center mt-0 mt-2 ' style={{color:'white'}}><span className="material-symbols-outlined mt-1" style={{fontSize:'18px'}}>apartment</span>{""}4 BHK Apartments</h5>
               <div className='text-center mt-3'>
               <button className='hero-btn fw-bold' onClick={onClick} style={{textTransform:'uppercase', color:'black'}}>Enquire Now &nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" style={{color:'#cee002'}} width="32" height="32" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>
                      </button>
               </div>

               {/* <div className='text-center mt-4 px-2'>
                <div className='d-flex justify-content-center align-items-center'>
                   <button className='newlaunch-rera-btn rounded-a  p-2 border-top '>New Launch</button>
                   <button className='newlaunch-rera-btn rounded-b p-2  '>Rera Approoved</button>
                </div>
               </div> */}

                </div>
                 </div>
         
          </div>







          <div className="col-12 d-md-none py-3">
          <div className="overview-section px-3">
                        <h2 className='text-center h3-pb' style={{color:'white'}}>Overview</h2>
                        <p className='' style={{textAlign:'justify'}}>Discover luxury living with this exquisite 4BHK flat by Pankh Developers in Sector 21C, Faridabad. Spanning 500 sq. yds., this residence features superior construction, premium Italian marble flooring, sunlit rooms, home automation and a high-end modular kitchen. Located near the Manav Rachna-Badkal Mor Metro, just 2 km away, it ensures excellent connectivity. Enjoy spa-inspired bathrooms, elegant living spaces, and a secure, stylish facade, all in a prime area known for its clean environment, top-notch schools, and vibrant lifestyle amenities.</p>
                    </div>
          </div>
        </div>
      </div>


      {/* desktop */}

      <div className="container-fluid p-0 d-none d-md-block section1-desktop" >
            
              <div className="row ">
              
                <div className="col-md-5 d-flex align-items-center position-relative">
                   <div className='  ps-5 py-3'>
                     <div className='pb-5'>
                     <h4 className='desktop-hero-heading text-start' style={{textTransform:'uppercase'}}>Exquisite 4BHK Flat by Pankh Developers</h4>

                     </div>
                   <div className="desktop-price-container mt-5">
                    <div className='desktop-price-hero-text  ' >
                    <span class="material-symbols-outlined" style={{fontSize:'30px', overflow:'hidden'}}>
                      payments
                      </span>&nbsp; &nbsp;
                      <span>Price : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-currency-rupee mb-1" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                </svg> 3.5 Cr. Onwards</span> <b></b></div>
                    <div className='desktop-price-hero-text'>
                    <span class="material-symbols-outlined" style={{fontSize:'30px', overflow:'hidden'}}>
                      apartment
                      </span>
                     &nbsp; <span>4 BHK Apartment</span>
                    </div>
                    <div className='my-3'>
                      <button className='hero-btn fw-bold' onClick={onClick} style={{textTransform:'uppercase'}}>Enquire Now &nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" style={{color:'#cee002'}} width="32" height="32" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>
                      </button>
                    </div>
                   </div>
                   </div>

                  

                </div>

                <div className="col-md-7 section1-desktop position-relative  d-flex justify-content-end align-items-center" style={{overflow:'hidden'}}>
                <div className='background-dots'></div>
                     <div className="carousel-desktop-container position-relative" style={{zIndex:'99'}}>
                     <Carousel controls={false} indicators={true} activeIndex={index} onSelect={handleSelect} style={{ width: '100%' }}>
              {arrayimg?.map((val) => (
                <Carousel.Item key={val.id} className=''>
                  <img src={val.img} onClick={() => setBasicExampleOpen(true)} style={{cursor:'pointer', objectFit:'cover'}} className='hero-carousel-photos-desktop ' alt="" />
                </Carousel.Item>
              ))}
            </Carousel>
            
            <div className="top-box-location d-flex justify-content-center align-items-start">
              <div className='location-hero-container px-1'>
              <h5 className='bhk-text1-desktop text-center mt-0 mt-2 px-3' style={{color:''}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt mb-1" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>{" "}
Sector 21C, Faridabad</h5>
                 
              </div>
            </div>
              {/* <div className='desktop-rera-btn position-absolute d-flex justify-content-center '>
              <div className='d-flex justify-content-center align-items-center desktop-rera' >
                   <button className='newlaunch-rera-btn rounded-a  p-2 border-top '>New Launch</button>
                   <button className='newlaunch-rera-btn rounded-b p-2  '>Rera Approoved</button>
                </div>
              </div> */}
            

                     </div>
                </div>


               
              </div>
            
      </div>

      <div className=" d-none d-md-block container-fluid section-py overview-container" >
        <div className="container">
        <div className="col-12 d-flex justify-content-center  ">
          <div className="overview-section position-relative" style={{width:'80%'}}>
                        <h2 className='text-center '>Overview</h2>
                        <p className='text-center'>Discover luxury living with this exquisite 4BHK flat by Pankh Developers in Sector 21C, Faridabad. Spanning 500 sq. yds., this residence features superior construction, premium Italian marble flooring, sunlit rooms, home automation and a high-end modular kitchen. Located near the Manav Rachna-Badkal Mor Metro, just 2 km away, it ensures excellent connectivity. Enjoy spa-inspired bathrooms, elegant living spaces, and a secure, stylish facade, all in a prime area known for its clean environment, top-notch schools, and vibrant lifestyle amenities.</p>
                    </div>
          </div>
        </div>
      
      </div>



    </>
  )
}

export default HeroAndOverview