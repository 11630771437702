import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formSubmitted = location.state?.formSubmitted;

  useEffect(() => {
    if (!formSubmitted) {
      navigate('/pankhdevelopers/4bhk-luxury-faridabad');
    }

    // Adding Google Tag Manager script dynamically
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16594647001";

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16594647001');
    `;

    document.head.appendChild(script1);
    document.head.appendChild(script2);

    // Cleanup: remove the scripts when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, [formSubmitted, navigate]);

  return (
    <div>
      <div className="thank-you-container" style={{ background: '#272727' }}>
        <div className="thank-you-card">
          <span style={{ fontSize: '90px', overflow: 'hidden', color: 'green' }} className="material-symbols-outlined">task_alt</span>

          <h1 style={{ color: 'white' }} className='text-center'>Thank You!</h1>
          <p className='mb-0 thankyou-p' style={{ lineHeight: 'normal' }}>We appreciate your interest in our property. <br />Your submission has been received successfully.</p>
          <p className='mt-0 thankyou-p' style={{ lineHeight: 'normal' }}>Our team will get back to you shortly.</p>
          <button className="back-to-home-btn" onClick={() => navigate("/pankhdevelopers/4bhk-luxury-faridabad")}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ color: 'black' }} width="32" height="32" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
            </svg>
            &nbsp; Back To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
