import React from 'react'

function SizeAndPriceSection() {
  return (
      <div id='section5' className="container-fluid py-4 py-md-5 position-relative">
          <div className="section5-bg-dots"></div>
        <div className="container">
            <div className="row">
                <div className="col-12 " >
                <h3 className='text-center pb-3' style={{color:'white'}}>Size & Price</h3>
                <div className='d-flex align-items--center justify-content-center'>
                <table className="table-sizeandprice" style={{ overflow:'hidden'  }}>
                    <thead className=''>
                        <tr>
                        <th>Size</th>
                        <th>Type</th>
                        <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>500 SQ. YDS.</td>
                        <td>4BHK </td>
                        <td className='borderrad-bottom'  style={{}}>3.5 Cr</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
               


                   
                    {/* <div className="row gap-0 justify-content-center">
                        <div className="col-3 border">
                            <div className="box-1">
                                <h2 className='text-center'>Size</h2>
                                <p className='text-center'>1920</p>
                            </div>
                        </div>
                        <div className="col-4 border-top border-bottom">
                        <div className="box-1 ">
                                <h2 className='text-center'>Size</h2>
                                <p className='text-center'>3BHK</p>
                            </div>
                        </div>
                        <div className="col-3 border">
                        <div className="box-1">
                                <h2 className='text-center'>Price</h2>
                                <p className='text-center'>3.5 Cr</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
      </div>
  )
}

export default SizeAndPriceSection